import {
  Divider,
  Notification,
  CharacterCounter,
  Paragraph,
  Link,
} from '@beamery/lib-ds-components';
import type {
  TypeaheadElement,
  FileElement,
  LocationProviderResult,
} from 'form-definition';
import { TEXT_ELEMENT_MAX_LENGTH, isTextElement } from 'form-definition';
import { useState, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DisplayRichText } from '@beamery/component-rich-text';
import { useLogger, ServerError } from 'utils';
import { Trans } from 'react-i18next';
import { useTranslation } from '../../hooks/use-translation';
import { I18nProvider } from '../../i18n/i18n-provider';
import { CustomFontProvider } from '../custom-font-provider';
import {
  Button,
  Image,
  FileInput,
  Select,
  CompanyLogo,
  LocationPicker,
  FormInput,
  SocialIcons,
} from './elements';
import { toFieldValidationRules } from './form-definition-to-form-fields';
import type { FormProps, Submission, WrapperProps } from './form.interface';
import { HtmlForm, NonFormContainer } from './form.styles';
import { Compliance } from './elements/compliance';
import type { TypeaheadOption } from './elements/core-typeahead/core-typeahead.interface';
import { UniversityPicker } from './elements/university-picker';
import { ReadonlyInput } from './elements/readonly-input';
import {
  ensureString,
  ensureFileValue,
  ensureFileName,
  ensureArray,
  ensureTypeaheadOption,
  hasValue,
} from './utils';
import { Buttons } from './elements/buttons';

const Wrapper = ({
  onSubmit,
  formDefinition,
  page,
  children,
}: WrapperProps) => {
  if (formDefinition.pages[page].pageType === 'form') {
    return (
      <HtmlForm
        onSubmit={onSubmit}
        label={formDefinition.settings.externalName}
      >
        {children}
      </HtmlForm>
    );
  }

  return <NonFormContainer>{children}</NonFormContainer>;
};

const FormComponent = ({
  formDefinition,
  onSubmit,
  page,
  onGoBack,
  onReset,
  isLoggedIn,
}: FormProps) => {
  const { t } = useTranslation();
  const logger = useLogger();

  const fieldValidationRules = useMemo(
    () => toFieldValidationRules(formDefinition, t),
    [formDefinition, t],
  );
  const { flexibleOptions } = formDefinition;
  const [submissionError, setSubmissionError] = useState<string | null>(null);

  const { formState, control, reset, handleSubmit, setValue } =
    useForm<Submission>({
      mode: 'all',
    });

  const isMultiPageFlow = formDefinition.pages.length > 2;
  const submitForm = async (data: Submission) => {
    const submission = {
      ...data,
    };
    try {
      setSubmissionError(null);

      const typeaheads = formDefinition.pages
        .flatMap(({ elements }) => elements)
        .filter(
          (element): element is TypeaheadElement =>
            element.type === 'typeahead',
        );
      typeaheads.forEach((typeahead) => {
        if (typeahead.field === null) {
          return;
        }
        const fieldValue = submission[typeahead.id];
        if (typeof fieldValue !== 'string' || fieldValue.length === 0) {
          if (typeahead.meta.multi) {
            submission[typeahead.id] = [];
          }
          return;
        }

        const parsedData = JSON.parse(fieldValue) as TypeaheadOption[];
        if (typeahead.meta.multi) {
          submission[typeahead.id] = parsedData.map(({ value }) => value);
        } else if (parsedData.length === 0) {
          submission[typeahead.id] = '';
        } else {
          submission[typeahead.id] = parsedData[0].value;
        }
      });

      const files = formDefinition.pages
        .flatMap(({ elements }) => elements)
        .filter((element): element is FileElement => element.type === 'file');

      // if a user has not uploaded a new File or cleared the input set it to the orginal file id if it exists
      files.forEach((file) => {
        const fieldValue = submission[file.id];
        if (fieldValue && !(fieldValue instanceof File)) {
          const [_, uploadedFileId = ''] = file.value || [];
          submission[file.id] = uploadedFileId;
        }
      });

      await onSubmit(submission);

      // strip file references out on successful submission to prevent any re-upload of files
      files.forEach((file) => {
        const fieldValue = submission[file.id];
        if (fieldValue instanceof File) {
          setValue(file.id, fieldValue.name);
        }
      });
    } catch (e) {
      if (e instanceof ServerError) {
        setSubmissionError(t('error.submit-error'));
      } else if (e instanceof Error && e.message) {
        setSubmissionError(e.message);
      } else {
        setSubmissionError(t('error.generic-error'));
      }
      logger.error(e, { flowId: formDefinition.id });
    }
  };

  const isFinalFormPage =
    page === formDefinition.pages.findLastIndex((p) => p.pageType === 'form');
  const isFirstFormPage =
    page === formDefinition.pages.findIndex((p) => p.pageType === 'form');

  const handleReset = () => {
    if (!isLoggedIn) {
      reset();
    }
    onReset?.();
  };

  /**
   * To add a new element type:
   *   - create your component in either a new file or folder (or use one from the design system)
   *   - import the component in this file
   *   - add a new case to the switch statement below
   *   - add a new case to toFormFields() in ./form-definition-to-form-fields.ts
   *   - add tests for the new element type
   */

  return (
    <Wrapper
      onSubmit={handleSubmit(submitForm)}
      formDefinition={formDefinition}
      page={page}
    >
      {formDefinition.pages[page].elements.map((element) => {
        switch (element.type) {
          case 'text':
          case 'email':
          case 'phone':
          case 'date': {
            const isReadonlyAuthenticatedEmailField =
              isLoggedIn && element.field === 'email';
            hasValue(ensureString(element.value));

            return (
              <Controller
                key={element.id}
                control={control}
                name={element.id}
                rules={fieldValidationRules[element.field]}
                defaultValue={ensureString(element.value)}
                render={({
                  field: { onChange, onBlur, value, ref, name },
                  fieldState: { error },
                }) => (
                  <>
                    {isReadonlyAuthenticatedEmailField ? (
                      <ReadonlyInput
                        key={element.id}
                        label={element.meta.label}
                        value={value as string}
                      />
                    ) : (
                      <div>
                        <FormInput
                          key={element.id}
                          label={element.meta.label}
                          onChange={onChange}
                          onBlur={() => {
                            onChange((value as string).trim());
                            onBlur();
                          }}
                          ref={ref}
                          value={value as string}
                          name={name}
                          type={element.type === 'phone' ? 'tel' : element.type}
                          isRequired={element.required}
                          maxLength={
                            isTextElement(element)
                              ? TEXT_ELEMENT_MAX_LENGTH
                              : undefined
                          }
                          errorMessage={error?.message}
                          fullWidth
                        />
                        {isTextElement(element) && (
                          <CharacterCounter
                            maxLength={TEXT_ELEMENT_MAX_LENGTH}
                            value={value as string}
                            message={(count: number) =>
                              `${
                                flexibleOptions?.characterLimitMessage ||
                                t('character-limit.characters-remaining')
                              }: ${count}`
                            }
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
              />
            );
          }
          case 'file':
            return (
              <Controller
                key={element.id}
                control={control}
                name={element.id}
                rules={fieldValidationRules[element.field]}
                defaultValue={ensureFileName(element.value)}
                render={({
                  field: { onChange, onBlur, value, ref, name },
                  fieldState: { error },
                }) => (
                  <FileInput
                    name={name}
                    label={element.meta.label}
                    sizeLimit={element.meta.sizeLimit}
                    acceptedTypes={element.meta.acceptedTypes}
                    value={ensureFileValue(value)}
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    error={error}
                    isRequired={element.required}
                    messages={element.meta.messages}
                  />
                )}
              />
            );
          case 'select':
            return (
              <Controller
                key={element.id}
                control={control}
                name={element.id}
                rules={fieldValidationRules[element.field]}
                defaultValue={
                  element.meta.multi
                    ? ensureArray(element.value)
                    : ensureString(element.value)
                }
                render={({
                  field: { onChange, onBlur, value, ref, name },
                  fieldState: { error },
                }) => (
                  <Select
                    name={name}
                    multi={element.meta.multi}
                    label={element.meta.label}
                    options={element.meta.options}
                    required={element.required}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error}
                    flexibleOptions={flexibleOptions}
                    sortOptionsAlphabetically={
                      element.meta.sortOptionsAlphabetically ?? false
                    }
                    value={
                      (value === '' ? null : value) as string | string[] | null
                    }
                    ref={ref}
                  />
                )}
              />
            );
          case 'typeahead': {
            const TypeaheadComponent =
              element.meta.source === 'location'
                ? LocationPicker
                : UniversityPicker;

            const locationLabelPropertyName: keyof LocationProviderResult =
              'formattedAddress';

            return (
              <Controller
                key={element.id}
                control={control}
                name={element.id}
                rules={fieldValidationRules[element.field]}
                defaultValue={ensureTypeaheadOption(
                  element.value,
                  element.meta.source === 'location'
                    ? locationLabelPropertyName
                    : null,
                )}
                render={({
                  field: { onChange, onBlur, value, ref, name },
                  fieldState: { error },
                }) => (
                  <TypeaheadComponent
                    ref={ref}
                    isMulti={element.meta.multi}
                    name={name}
                    isRequired={element.required}
                    onChange={(values) =>
                      onChange(values.length > 0 ? JSON.stringify(values) : '')
                    }
                    value={
                      typeof value === 'string' && value.length > 0
                        ? (JSON.parse(value) as TypeaheadOption[])
                        : []
                    }
                    onBlur={onBlur}
                    label={element.meta.label}
                    errorMessage={error?.message}
                    noResultsMessage={
                      flexibleOptions?.typeaheadNoResultsMessage ?? ''
                    }
                  />
                )}
              />
            );
          }
          case 'dataProtection':
            if (formDefinition.companySettings.compliances.length === 0) {
              return null;
            }

            return (
              <Compliance
                key={element.id}
                fieldName={element.id}
                current={element.value}
                compliance={formDefinition.companySettings.compliances[0]}
                fieldValidationRules={fieldValidationRules}
                control={control}
                isLoggedIn={isLoggedIn}
              />
            );
          case 'button':
            if (isMultiPageFlow) {
              return (
                <Buttons
                  key={element.id}
                  action={element.meta.action}
                  loading={formState.isSubmitting}
                  onReset={handleReset}
                  onGoBack={onGoBack}
                  showSubmitButton={isFinalFormPage}
                  showBackButton={!isFirstFormPage}
                  flexibleOptions={flexibleOptions}
                />
              );
            }
            return (
              <Button
                key={element.id}
                action={element.meta.action}
                loading={formState.isSubmitting}
                onReset={handleReset}
              >
                {element.meta.label}
              </Button>
            );
          case 'richtext':
            // Wrapped in a div to prevent extra gaps between blocks within the content
            return Array.isArray(element.meta.content) &&
              element.meta.content.length > 0 ? (
              <div key={element.id}>
                <DisplayRichText content={element.meta.content} />
              </div>
            ) : null;
          case 'image':
            return element.meta.src ? (
              <Image
                key={element.id}
                src={element.meta.src}
                alt={element.meta.alt ?? ''}
              />
            ) : null;
          case 'companyLogo':
            return (
              <CompanyLogo
                key={element.id}
                settings={formDefinition.settings}
                companySettings={formDefinition.companySettings}
              />
            );
          case 'divider':
            return <Divider key={element.id} />;
          case 'socialIcons':
            return (
              <SocialIcons
                key={element.id}
                socialMedia={formDefinition.companySettings.socialMedia}
              />
            );
          default:
            return null;
        }
      })}
      {submissionError && (
        <Notification variant='banner' status='error' label={submissionError} />
      )}
      {formDefinition.pages[page].pageType === 'form' &&
        formDefinition.companySettings.recaptchaEnabled && (
          <Paragraph variant='small'>
            <Trans i18nKey='recaptcha' t={t}>
              This site is protected by reCAPTCHA and the Google{' '}
              <Link
                variant='small'
                setting='inline'
                href='https://policies.google.com/privacy'
              >
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link
                variant='small'
                setting='inline'
                href='https://policies.google.com/terms'
              >
                Terms of Service
              </Link>{' '}
              apply.
            </Trans>
          </Paragraph>
        )}
    </Wrapper>
  );
};

export const Form = ({
  isLoggedIn,
  formDefinition,
  ...restProps
}: FormProps) => (
  <I18nProvider>
    <CustomFontProvider
      fontFamilyName={formDefinition.companySettings.customFontFamilyName}
      fontUrl={formDefinition.companySettings.customFontUrl}
    >
      <FormComponent
        {...restProps}
        isLoggedIn={isLoggedIn}
        formDefinition={formDefinition}
      />
    </CustomFontProvider>
  </I18nProvider>
);
