import type { CookieConsent } from 'form-definition';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { noop } from 'utils';
import { useCurrentDomain } from 'candidate-facing-components';
import type {
  OneTrustContextValue,
  OneTrustProviderProps,
} from './onetrust.interface';
import { injectOneTrust } from './utils';

const defaultValue: OneTrustContextValue = {
  addAcceptedListener: noop,
  setSettings: noop,
};

const OneTrustContext = createContext(defaultValue);

export const OneTrustProvider = ({ children }: OneTrustProviderProps) => {
  const [settings, setSettings] = useState<CookieConsent | null>(null);
  const listenersRef = useRef<Record<string, (() => void)[]>>({});
  const acceptedRef = useRef<string[]>([]);
  const { isVanityDomain } = useCurrentDomain();

  useEffect(() => {
    if (settings === null) {
      return;
    }
    const handleGroupAccepted = (group: string) => {
      acceptedRef.current.push(group);
      listenersRef.current[group]?.forEach((cb) => cb());
      listenersRef.current[group] = [];
    };

    if (!settings.enabled) {
      ['C0001', 'C0002', 'C0003', 'C0004', 'C0005'].forEach(
        handleGroupAccepted,
      );
      return;
    }
    injectOneTrust({
      settings,
      isEventsUrl:
        !isVanityDomain && window.location.hostname.startsWith('events'),
      callback: handleGroupAccepted,
      isVanityDomain,
    });
  }, [settings, isVanityDomain]);

  const addAcceptedListener = useCallback(
    (group: string, listener: () => void) => {
      if (acceptedRef.current.includes(group)) {
        listener();
        return undefined;
      }
      listenersRef.current[group] = listenersRef.current[group] ?? [];
      listenersRef.current[group].push(listener);
      return () => {
        listenersRef.current[group] = listenersRef.current[group]?.filter(
          (cb) => cb !== listener,
        );
      };
    },
    [],
  );

  const value = useMemo<OneTrustContextValue>(
    () => ({
      addAcceptedListener,
      setSettings,
    }),
    [addAcceptedListener],
  );

  return (
    <OneTrustContext.Provider value={value}>
      {children}
    </OneTrustContext.Provider>
  );
};

export const useOneTrustSettings = (settings: CookieConsent | null) => {
  const { setSettings } = useContext(OneTrustContext);

  useEffect(() => {
    setSettings(settings);
  }, [setSettings, settings]);
};

export const useOneTrustCallback = (group: string, callback: () => void) => {
  const { addAcceptedListener } = useContext(OneTrustContext);
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(
    () => addAcceptedListener(group, () => callbackRef.current()),
    [addAcceptedListener, group],
  );
};
